export const environment = {
  name: 'production',
  tag: 'prod',
  appVersion: require('../../package.json').version,
  production: true,
  isDev: false,
  allowedDomains: ['api.gaia-engine.com'],
  disallowedRoutes: [],
};

export const API_URL = 'https://api.gaia-engine.com';
export const MAPBOX_KEY = 'pk.eyJ1IjoiYW50b25pb2dhaWEiLCJhIjoiY2w4Y3ZqMXl5MTMybTN1cWtvcnN5b2V3dSJ9.z2viUrREXoaWm5CNHGOWmA';
